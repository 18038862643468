<template>
    <div>
        <s-header :name="'食品溯源'"></s-header>
        <van-tabs v-model:active="active" @click-tab="onClickTab">
            <van-tab title="产品信息">
                <div class="topImg"></div>
                <div class="top">
                    <span>{{info && info.product_name}} {{info && info.product_specs}}</span>
                    <span class="iconfont icon-anquanyupinzhizhuisu" style="color: #085C92; font-size: 30px"></span>
                </div>
                <div class="table-box">
                    <van-cell-group border>
                        <van-cell title="产品名称" :value="info && info.product_name" />
                        <van-cell title="品牌" :value="info && info.goods && info.goods.product_brand" />
                        <van-cell title="执行标准" :value="info && info.goods && info.goods.standards" />
                        <van-cell title="产品介绍" :value="info && info.introduce" />
                        <van-cell title="条形码" :value="info && info.goods && info.goods.barcode" />
                        <van-cell title="联系方式" :value="info && info.remark" />
                        <van-cell title="许可证编号" :value="info && info.licence_no" />
                        <van-cell title="销售区域" :value="info && info.sales_area" />
                        <van-cell title="产品分类" :value="info && info.goods && info.goods.category && info.goods.category.name" />
                        <van-cell title="净含量" :value="info && info.net_content" />
                        <van-cell title="保质期" :value="info && info.goods && info.goods.shelf_life_days && (info.goods.shelf_life_days + (info.goods.period_unit === '月' ? '个月' : info.goods.period_unit))" />
                        <van-cell title="贮存条件" :value="info && info.hoarding" />
                        <van-cell title="生产企业" :value="info && info.commpany" />
                        <van-cell title="生产企业地址" :value="info && info.adreess" />
                        <van-cell title="配料表" :value="info && info.ingredients" />
                    </van-cell-group>
                </div>
            </van-tab>
            <van-tab title="追溯信息">
                <p class="dataWrap" @click="onClick">
                    <van-icon name="search" style="margin-right: 12px" />
                    <span v-if="!value">请选择生产日期</span>
                    <span style="color: #333" v-else>{{ value }}</span>
                    <van-icon @click.stop="defaultData" style="float: right; margin-top: 3px" name="cross" />
                </p>
                <div class="searchOn">
                    <p class="top1">快捷查询</p>
                    <p class="list1">
                        <span v-for="(val, index) in batchList" :key="index" @click="onBatch(val.batch, index)" :class="noIndex === index ? 'clickActive' : ''">{{ val.batch }}</span>
                    </p>
                </div>
                <div class="searchOn">
                    <p class="top1">配料编号</p>
                    <p class="list1">
                        <span v-for="(val, index) in numberList" :key="index" @click="onBatch1(val, index)" :class="noIndex1 === index ? 'clickActive' : ''">{{ val }}</span>
                    </p>
                </div>
                <div class="table-box">
                    <van-cell-group border>
                        <!-- <van-cell title="配料编号" :value="batchInfo && batchInfo.formulas_number" /> -->
                        <van-cell title="批次" :value="batchInfo && batchInfo.batch" />
                        <van-cell title="生产日期" :value="batchInfo && batchInfo.date" />
                        <van-cell title="保质期至" :value="batchInfo && batchInfo.period" />
                    </van-cell-group>
                </div>
                <!-- <van-search @click="onClick" v-model="value" placeholder="请选择生产日期" /> -->
                <van-popup
                        :show="showPop"
                        position="bottom"
                        label="有效日期"
                        custom-style="height: 50%; border: 1px solid #ccc"
                        @close="onCancel"
                >
                    <van-date-picker
                            @cancel="onCancel"
                            @confirm="onConfirm"
                            v-model="currentDate"
                            title="选择日期"
                            :min-date="minDate"
                            :max-date="maxDate"
                    />
                </van-popup>
                <div class="list">
                    <van-steps direction="vertical" :active="stepList && stepList.length">
                        <van-step v-for="(val, index) in stepList" :key="index">
                            <template #inactive-icon>
                                <span class="iconfont" :class="val.icon || 'icon-yanshou'"></span>
                            </template>
                            <template #active-icon>
                                <span class="iconfont icon-anquanyupinzhizhuisu"></span>
                            </template>
                            <p class="title">{{ val.title }}</p>
                            <div style="display: flex;justify-content: space-between">
                                <div>
                                    <span v-if="val.describe_y || val.describe_s" style="display: inline-block; padding-bottom: 10px; border-bottom: 1px solid #ccc">
                                        <span v-if="index === 0">
                                            <span class="tip" v-for="(node, index1) in val.describe_y" :key="index1">
                                                {{ node }}
                                            </span>
                                        </span>
                                        <span v-if="index === 1">
                                            <span class="tip" v-for="(node, index1) in val.describe_s" :key="index1">
                                                {{ node }}
                                            </span>
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span class="tip" v-for="(node, index1) in val.result" :key="index1">
                                            {{ node }}
                                        </span>
                                    </span>
                                    <div v-if="index === 0">
                                        <div v-for="(node, index2) in val.record && val.record.material" :key="index2" style="padding-bottom: 10px; border-bottom: 1px solid #ccc">
                                            <p>原辅料名称 {{ node.product_name }}</p>
                                            <p>原材料批次 {{ node.material_batch }}</p>
                                            <span style="vertical-align: middle">检验报告 </span><img style="width: 60%; vertical-align: middle" :src="(node.report_img && node.report_img[0] && node.report_img[0].url) || node.report_img && node.report_img[0]" @click="onShow((node.report_img && node.report_img[0] && node.report_img[0].url) || node.report_img && node.report_img[0])" />
                                        </div>
                                    </div>
                                    <div v-if="index === 1">
                                        <p>投料时间 {{ val.record && val.record.start && val.record.start.split('T')[0] + '日' + val.record.start.split('T')[1] }}</p>
                                        <p>投料人 {{ val.record && val.record.recorder }}</p>
                                        <p>投料情况 {{ val.record && val.record.situation }}</p>
                                    </div>
                                    <div v-for="(item, count) in val.image" :key="count">
                                        <iframe v-if="isPDF((item && item.response) ?  (item.response.data && item.response.data.file_url) : item)" :src="(item && item.response) ?  (item.response.data && item.response.data.file_url) : item" width="100%" height="300px"></iframe>
                                        <img v-else style="width: 100%;" :src="(item && item.response) ?  (item.response.data && item.response.data.file_url) : item" @click="onShow((item && item.response) ?  (item.response.data && item.response.data.file_url) : item)" />
                                    </div>
                                </div>
                                <!-- <p>
                                    <span v-for="(item, count) in val.image" :key="count" @click="showPopup(item)" style="margin-right: 10px">查看</span>
                                </p> -->
                            </div>
                        </van-step>
                    </van-steps>
                </div>
                <!-- <van-popup closeable v-model:show="show" :style="{ width: '80%' }">
                    <img style="width: 100%;" :src="imgSrc" />
                </van-popup> -->
            </van-tab>
            <van-tab title="企业信息">
                <div class="table-box">
                    <van-cell-group border>
                        <van-cell title="企业名称" :value="info1 && info1.title" />
                        <van-cell title="企业地址" :value="info1 && info1.address_name" />
                        <van-cell title="联系电话" :value="info1 && info1.mobile" />
                        <van-cell title="资质证书" center>
                            <template #right-icon>
                                <div style="display: flex; flex-direction: column">
                                    <img style="width: 80%; margin-bottom: 20px" v-for="(val, index) in info1 && info1.credential && info1.credential.certificate" :key="index" :src="val" @click="onShow(val)" />
                                </div>
                            </template>
                        </van-cell>
                        <van-cell title="许可范围" :value="info1 && info1.credential && info1.credential.scope" />
                    </van-cell-group>
                </div>
            </van-tab>
        </van-tabs>
        <van-popup v-model:show="isShow">
            <img style="width: 100%" :src="imgInfo" />
        </van-popup>
        <van-icon v-show="isShow" @click="isShow = false" class="crossIcon" name="cross" />
    </div>
</template>

<script>
    import sHeader from '@/components/SimpleHeader'
    import { reactive, onMounted, toRefs, ref } from 'vue'
    import { getDetails, getList, getInfo, getBatch } from '@/service/trace'
    export default {
        name: "Trace",
        components: {
            sHeader
        },
        setup() {
            const isShow = ref(false);
            const imgInfo = ref('')
            const batchList = ref([])
            const numberList = ref([])
            const active = ref(0);
            const currentDate = ref(['2021', '01', '01']);
            const showPop = ref(false);
            const value = ref('');
            const value1 = ref('');
            const info = ref(null);
            const info1 = ref(null);
            const id = ref('');
            const infoPici = ref({});
            // const show = ref(false);
            // const imgSrc = ref('')
            const state = reactive({
                currentDate,
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(2035, 5, 1),
                stepList: [
                    {
                        title: '原料验收',
                        result: ['原料索证齐全', '原料验收合格'],
                        icon: 'icon-yanshou',
                        image: []
                    },
                    {
                        title: '生产投料',
                        result: ['根据订单生产'],
                        icon: 'icon-shengchanlingliao',
                        image: []
                    },
                    {
                        title: '半成品检测',
                        result: ['半成品检测合格'],
                        icon: 'icon-jiance',
                        image: []
                    },
                    {
                        title: '成品检测',
                        result: ['成品检测合格'],
                        icon: 'icon-jiance',
                        image: []
                    },
                    {
                        title: '成品出库',
                        result: ['成品出库检测合格'],
                        icon: 'icon-ruku',
                        image: []
                    }
                ],
                batchInfo: {},
                noIndex: null,
                noIndex1: 0
            })
            onMounted(async () => {
                var url = window.location.href;
                var dz_url = url.includes('?') ? url.split('?')[1].split('=')[1] : '1';
                id.value = dz_url;
                const { data } = await getDetails({product_id: dz_url})
                info.value = data;
                const res = await getInfo({product_id: dz_url})
                const res1 = await getBatch({product_id: dz_url})
                batchList.value = res1.data.pici.slice(0, 6)
                info1.value = res.data
            })
            function onClickTab() {
                if(active.value == 1) {
                    getBatchInfo(value1.value)
                }
            }
            async function getBatchInfo(val) {
                const res = await getList({product_id: id.value, date: val})
                let nowStr = res.message.formulas_number && res.message.formulas_number[0]
                numberList.value = res.message && res.message.formulas_number
                state.stepList = res.data[nowStr]
                infoPici.value = res.data
                state.batchInfo = res.message
            }
            function onBatch(val, index) {
                getBatchInfo(val)
                state.noIndex = index
            }
            async function onBatch1(val, index) {
                state.noIndex1 = index
                state.stepList = infoPici.value[val]
            }
            function isPDF(link) {
                return link.toLowerCase().endsWith('.pdf');
            }
            function onShow(val) {
                imgInfo.value = val
                isShow.value = true
            }
            function onClick() {
                showPop.value = true
            }
            async function defaultData() {
                if(value.value) {
                    const res = await getList({product_id: id.value, date: ''})
                    value.value = ''
                    value1.value = ''
                    state.stepList = res.data
                }
            }
            function onCancel() {
                showPop.value = false
            }
            async function onConfirm(val) {
                showPop.value = false
                state.noIndex = null
                value.value = val.selectedValues.join('-')
                value1.value = val.selectedValues.join('')
                const res = await getList({product_id: id.value, date: val.selectedValues.join('')})
                let nowStr = res.message.formulas_number && res.message.formulas_number[0]
                numberList.value = res.message && res.message.formulas_number
                state.stepList = res.data[nowStr]
                infoPici.value = res.data
                state.batchInfo = res.message
            }
            /* function showPopup(item) {
                show.value = true;
                imgSrc.value = item;
            } */
            return {
                active,
                onBatch,
                onClickTab,
                onClick,
                showPop,
                onBatch1,
                batchList,
                numberList,
                infoPici,
                onShow,
                imgInfo,
                onCancel,
                onConfirm,
                getBatchInfo,
                defaultData,
                value,
                value1,
                info,
                info1,
                id,
                isPDF,
                // show,
                isShow,
                // showPopup,
                // imgSrc,
                ...toRefs(state),
            }
        }
    }
</script>

<style scoped lang="less">
    .dataWrap{
        font-size: 16px;
        padding: 8px 20px;
        margin: 20px;
        border: 2px solid #e05304;
        color: #ddd;
    }
    .searchOn{
        margin: 20px;
        color: #666;
        .top1{
            font-weight: bold;
            color: #333;
            font-size: 16px;
        }
        .list1{
            display: flex;
            flex-wrap: wrap;
            span{
                display: inline-block;
                width: 30%;
                margin-right: 2%;
                text-align: center;
                border: 1px solid #ddd;
                padding: 8px 0;
                background: #F7F7F7;
                border-radius: 5px;
                margin-bottom: 10px;
            }
            span.clickActive{
                color: #1989fa;
                border-color: #1989fa;
            }
        }
    }
    .crossIcon{
        position: fixed;
        z-index: 99999;
        font-size: 20px;
        top: 60px;
        color: #fff;
        right: 10px;
    }
    ::v-deep .van-cell__title {
        flex: none;
        width: 30%;
    }
    ::v-deep .van-search__content{
        border: 3px solid #E05304;
    }
    ::v-deep .van-step--finish .van-step__circle, ::v-deep .van-step--finish .van-step__line{
        background: #ccc;
    }
    .topImg{
        margin-top: 20px;
        height: 100px;
        background: url("~@/assets/bg.png") no-repeat center;
        background-size: cover;
    }
    .top{
        display: flex;
        margin: 0 20px;
        font-size: 20px;
        font-weight: bold;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        color: #D45510;
    }
    .info{
        height: 40px;
        line-height: 40px;
        margin: 0 0 12px;
        background: #E05304;
        color: #fff;
        padding-left: 20px;
        font-size: 16px;
    }
    .table-box{
        margin-bottom: 12px;
        ::v-deep .van-cell__value{
            text-align: left;
        }
    }
    ::v-deep .van-steps{
        padding-left: 60px;
    }
    .list{
        margin-bottom: 30px;
        .title{
            padding: 6px 10px;
            font-size: 16px;
            margin-top: 0;
            color: #1989fa;
        }
        .tip{
            font-size: 14px;
            color: #333;
            margin-right: 20px;
        }
    }
    .iconfont{
        color: #fff;
        display: inline-block;
        font-size: 16px;
        padding: 8px;
        background: #0FBDD1;
        border-radius: 50%;
    }
</style>
